<template>
    <section class="forms">
        <vue-snotify></vue-snotify>
        <div class="row new-updated-design">
            <div class="col-md-6 grid-margin stretch-card">
                <div class="card">
                    <did class="card-header">
                        <h4 class="card-title">{{ $t('update-profile') }}</h4>
                    </did>
                    <div class="card-body">
                        <form class="forms-sample">
                            <b-form-group :label="$t('name*')" label-for="input5">
                                <b-form-input type="text"
                                              v-model="$v.form.name.$model"
                                              :state="$v.form.name.$dirty ? !$v.form.name.$error : null"
                                              aria-describedby="input-1-live-feedback" id="input5"
                                              :placeholder="$t('name-placeholder')"></b-form-input>
                                <b-form-invalid-feedback id="input-1-live-feedback">{{ $t("field-required") }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                            <b-form-group :label="$t('email-address')" label-for="input6">
                                <b-form-input type="email" autocomplete="new-password"
                                              :placeholder="$t('email')"
                                              readonly
                                              v-model="form.email"
                                              aria-describedby="input-3-live-feedback"></b-form-input>
                            </b-form-group>
                            <b-form-group
                                    id="input-group-2"
                                    :label="$t('password')"
                                    label-for="input-2"
                            >
                                          <span class="icon is-small is-right"
                                                style="">
                                        <b-form-input
                                                id="input-2"
                                                autocomplete="new-password"
                                                :type="inputType"
                                                :placeholder="$t('password')"
                                                v-model="$v.form.password.$model"
                                                :state="$v.form.password.$dirty ? !$v.form.password.$error : null"
                                                aria-describedby="input-3-live-feedback"
                                        ></b-form-input>
                                         <b-form-invalid-feedback v-if="!$v.form.password.required"
                                                                  id="input-3-live-feedback">{{ $t("field-required") }}</b-form-invalid-feedback>
                                         <b-form-invalid-feedback v-if="!$v.form.password.minLength"
                                                                  id="input-3-live-feedback">Password must have at least {{$v.form.password.$params.minLength.min }} letters.</b-form-invalid-feedback>
                                         <b-form-invalid-feedback v-if="form.password !== '' && !$v.form.password.valid"
                                                                  id="input-3-live-feedback">{{ $t("Password contains atleast One Uppercase, One Lowercase, One Number and One Special Character.") }}</b-form-invalid-feedback>
                                      <i @click="toggleShow" class="fas password-icon"
                                         :class="{'fa fa-eye': showPassword, 'fa fa-eye-slash': !showPassword, }"></i></span>

                            </b-form-group>
                            <b-form-group
                                    id="input-group-4"
                                    :label="$t('confirm-password')"
                                    label-for="input-4"
                            >
                                          <span class="icon is-small is-right"
                                                style="">
                                        <b-form-input
                                                id="input-4"
                                                autocomplete="new-password"
                                                :type="inputType1"
                                                :placeholder="$t('confirm-password')"
                                                v-model="$v.form.password_confirmation.$model"
                                                :state="$v.form.password_confirmation.$dirty ? !$v.form.password_confirmation.$error : null"
                                                aria-describedby="input-4-live-feedback"
                                        ></b-form-input>
                                              <b-form-invalid-feedback v-if="!$v.form.password_confirmation.required"
                                                                       id="input-3-live-feedback">{{ $t("field-required") }}</b-form-invalid-feedback>
                                         <b-form-invalid-feedback v-if="!$v.form.password_confirmation.sameAsPassword"
                                                                  id="input-4-live-feedback">Passwords must be identical.</b-form-invalid-feedback>
                                      <i @click="toggleConfirmShow" class="fas password-icon"
                                         :class="{'fa fa-eye': showPassword1, 'fa fa-eye-slash': !showPassword1, }"></i></span>

                            </b-form-group>

                            <b-form-group :label="$t('upload-file')" label-for="input8">
                                <div class="col-md-4">
                                <img :src="url" alt="" width="200px" height="150px" class="mb-1" />
                            </div>
                                <b-form-file ref="fileInput" accept="image/png, image/jpeg, image/bmp" id="input8"
                                             :placeholder="$t('choose-file')" @change="onFileSelected"></b-form-file>
                                <a href="javascript:void(0)" @click="removeImage">{{ $t('remove-image') }} </a>
                            </b-form-group>
                            

                            <b-button type="button" variant="success" :disabled="isDisabled" @click="onSubmit()"
                                      class="mr-2">{{ $t('submit-btn') }}
                            </b-button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
    import Vue from "vue";
    import SortedTablePlugin from "vue-sorted-table";
    import API from '@/api'
    import Snotify, {SnotifyPosition} from 'vue-snotify'
    import {validationMixin} from "vuelidate";
    import {required, minLength, email, requiredIf, sameAs} from "vuelidate/lib/validators";

    const options = {
        toast: {
            position: SnotifyPosition.rightTop,
        }
    }
    Vue.use(Snotify, options)
    Vue.use(SortedTablePlugin, {
        ascIcon: '<i class="ti-arrow-down"></i>',
        descIcon: '<i class="ti-arrow-up"></i>'
    });
    export default {
        name: 'Users',
        mixins: [validationMixin],
        data() {
            return {
                form: {
                    name: "",
                    email: "",
                    password: "",
                    password_confirmation: "",
                    id: "",
                    title: "",
                    mobile_phone: "",
                    image: [],
                },
                isDisabled: false,
                showPassword: true,
                selectedFile: null,
                url: null,
                showPassword1: true,
                inputType: 'password',
                inputType1: 'password',
            };
        },
        validations: {
            form: {
                id: {},
                name: {
                    required,
                }, password: {
                    required: requiredIf((value) => value.password !== ''),
                    minLength: minLength(6),
                    valid: function (value) {
                        const containsUppercase = /[A-Z]/.test(value)
                        const containsLowercase = /[a-z]/.test(value)
                        const containsNumber = /[0-9]/.test(value)
                        const containsSpecial = /[#?!@$%^&*-]/.test(value)
                        return containsUppercase && containsLowercase && containsNumber && containsSpecial
                    },
                }, password_confirmation: {
                    sameAsPassword: sameAs('password')
                }

            }
        },
        methods: {
            getAuthUser() {
                this.$v.form.$reset();
                API.getAuthUser(
                    '', data => {
                        console.log(data);
                        this.form.name = data.name;
                        this.form.id = data.id;
                        this.form.email = data.email;
                        this.form.mobile_phone = data.mobile_phone;
                        this.form.title = data.title;
                        this.url = data.image;
                        console.log(data.image);
                    },
                    err => {
                    }
                )
            },
            onSubmit() {
                console.log(this.form)
                this.$v.form.$touch();
                if (this.$v.form.$anyError) {
                    if (!this.$v.form.password.valid && this.$v.form.password !== '' && this.form.name != '') {
                        this.isDisabled = true;
                        // this.userForm();
                    } else {
                        return;
                    }
                }
                if (this.form.name != '' && this.form.email != '') {
                    this.isDisabled = true;
                    this.userForm();
                } else {
                    this.$snotify.warning('Please fill required fields', {
                        timeout: 2000,
                        showProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                    this.isDisabled = false;
                }
            }, resetForm() {
                var self = this; //you need this because *this* will refer to Object.keys below`

                //Iterate through each object field, key is name of the object field`
                Object.keys(this.data.form).forEach(function (key, index) {
                    self.data.form[key] = '';
                });
            }, userForm() {

                const fd = new FormData();
                if (this.selectedFile && this.selectedFile.length !== 0) {
                    fd.append('file', this.selectedFile, this.selectedFile.name)
                }
                fd.append('data', JSON.stringify(this.form))


                API.updateUserProfile(
                    fd,
                    data => {
                        this.isDisabled = false;
                        console.log(data.message);
                        this.$snotify.success(data.message, {
                            timeout: 2000,
                            showProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                        });
                        this.$EventBus.$emit('updateLocalProfile', data);
                        this.$v.form.$reset();

                    },
                    err => {
                        this.isDisabled = false;
                        console.log(err['data']['errors']['email'][0]);
                        this.$snotify.error(err['data']['errors']['email'][0], {
                            timeout: 2000,
                            showProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                        });

                    }
                )
            },
            toggleShow() {
                this.showPassword = !this.showPassword;
                if (this.showPassword) {
                    this.inputType = 'password';
                } else {
                    this.inputType = 'text';
                }
            }, toggleConfirmShow() {
                this.showPassword1 = !this.showPassword1;
                if (this.showPassword1) {
                    this.inputType1 = 'password';
                } else {
                    this.inputType1 = 'text';
                }
            }, onFileSelected(event) {
                this.selectedFile = event.target.files[0]
                this.url = URL.createObjectURL(this.selectedFile)
                console.log(this.selectedFile, this.url)
            },
            removeImage() {
                this.selectedFile = [];
                this.url = '';
                this.$refs.fileInput.reset();
            }
        }, computed: {
            rows() {
                return this.numberOfRoles;
            }
        },
        mounted() {

        }, beforeMount() {
            this.getAuthUser();
        }
    }
</script>
